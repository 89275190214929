// @ts-check
// do not convert to ESM and/or TS -- this needs to be imported in CJS files like next.config.js too
module.exports = {
  // Site info
  siteName: "Rashid's blog",
  siteDomain: "rashid.so",
  siteLocale: "en-US",
  timeZone: "Europe/Stockholm", // https://en.wikipedia.org/wiki/List_of_tz_database_time_zones#List
  shortDescription: "Technology beyond borders",
  longDescription: "A DevOps | Cybersecurity Engineer, sharing knowledge.",
  role: "DevOps | Cybersecurity Engineer",
  license: "Creative Commons Attribution 4.0 International",
  licenseAbbr: "CC-BY-4.0",
  licenseUrl: "https://creativecommons.org/licenses/by/4.0/",
  copyrightYearStart: 2021,
  githubRepo: "rasabb/rashid.so",
  verifyGoogle: "lCReiCg8keD4J4Y9VdPnhq-G4CQ4XUoj9mmR7J9TlOU",
  PGPKeyId: "C599 B3D7 1653 4642",
  longDateFormat: "YYYY-MM-DD, HH:mm:ss Z",
  shortDateFormat: "YYYY-MM-DD",
  dayDateFormat: "D MMMM, YYYY",

  // Me info
  authorName: "Rashid A.",
  authorEmail: "shilsare@gmail.com",
  authorSocial: {
    github: "rasabb",
    twitter: "shilsare",
    keybase: "raashid",
    medium: "rasabb",
    linkedin: "rasabb",
    instagram: "raa5hid"
  }
};
