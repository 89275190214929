import Link from "../Link";
import { HeartIcon, NextjsLogo, VercelLogo, LinkedinLogo, GithubLogo, MailLogo } from "../Icons";
import { styled, theme, keyframes } from "../../lib/styles/stitches.config";
import * as siteMetadata from "../../lib/config/siteMetadata";
import type { ComponentProps } from "react";
import { authorSocial, authorName, authorEmail } from "../../lib/config/siteMetadata";

const Wrapper = styled("footer", {
  width: "100%",
  padding: "1.25em 1.5em",
  borderTop: `1px solid ${theme.colors.kindaLight}`,
  backgroundColor: theme.colors.backgroundOuter,
  color: theme.colors.mediumDark,
  transition: `background ${theme.transitions.fade}, border ${theme.transitions.fade}`,

  "@medium": {
    padding: "1em 1.25em"
  }
});

const Row = styled("div", {
  display: "flex",
  width: "100%",
  maxWidth: theme.sizes.maxLayoutWidth,
  margin: "0 auto",
  justifyContent: "space-between",
  fontSize: "0.85em",
  lineHeight: 2.3,

  // stack columns on left instead of flexboxing across
  "@medium": {
    fontSize: "0.8em",
    display: "block"
  }
});

const PlainLink = styled(Link, {
  color: theme.colors.mediumDark
});

const NextjsLink = styled(PlainLink, {
  "&:hover, &:focus-visible": {
    color: theme.colors.medium
  }
});

const ViewSourceLink = styled(PlainLink, {
  paddingBottom: "2px",
  borderBottom: `1px solid ${theme.colors.light}`,

  "&:hover, &:focus-visible": {
    borderColor: theme.colors.kindaLight
  }
});

const Icon = styled("svg", {
  width: "1.25em",
  height: "1.25em",
  verticalAlign: "-0.25em",
  margin: "0 0.075em",
  fill: "currentColor"
});

const Heart = styled("span", {
  display: "inline-block",
  color: theme.colors.error, // somewhat ironically color the heart with the themed "error" red... </3

  "@media (prefers-reduced-motion: no-preference)": {
    animation: `${keyframes({
      "0%": { transform: "scale(1)" },
      "2%": { transform: "scale(1.25)" },
      "4%": { transform: "scale(1)" },
      "6%": { transform: "scale(1.2)" },
      "8%": { transform: "scale(1)" },
      // pause for ~9 out of 10 seconds
      "100%": { transform: "scale(1)" }
    })} 10s ease 7.5s infinite`,
    willChange: "transform"
  }
});

export type FooterProps = ComponentProps<typeof Wrapper>;

const Footer = ({ ...rest }: FooterProps) => {
  const date = new Date();
  const year = date.getFullYear();
  return (
    <Wrapper {...rest}>
      <Row>
        <div>
          License:{" "}
          <PlainLink href="/license/" prefetch={false} title={siteMetadata.license} underline={false}>
            {siteMetadata.licenseAbbr}
          </PlainLink>
          , {siteMetadata.copyrightYearStart} – {year}.
          {/*© {new Date(process.env.RELEASE_DATE || Date.now()).getUTCFullYear()} Rashid A. All rights reserved.*/}
          {/*©{" "} {year} Rashid A.*/}
        </div>
        {/*<div>
          <NextjsLink
            href={`https://www.linkedin.com/in/${authorSocial.linkedin}`}
            title={`${authorName} on LinkedIn`}
            aria-label="Next.js"
            underline={false}
          >
            <Icon as={LinkedinLogo} />
          </NextjsLink>{" "}
          <NextjsLink
            href={`https://github.com/${authorSocial.github}`}
            title={`${authorName} on GitHub`}
            aria-label="Next.js"
            underline={false}
          >
            <Icon as={GithubLogo} />
          </NextjsLink>{" "}
          <NextjsLink
            href={`mailto:${authorEmail}?subject=Discussion&body=I%20was%20on%20your%20site%20and%20was%20wondering%20if%20you...`}
            title="Mail"
            aria-label="Next.js"
            underline={false}
          >
            <Icon as={MailLogo} />
          </NextjsLink>
          <ExternalLink link="https://twitter.com/shilsare">
            <span className="sr-only">Twitter (opens in a new tab)</span>
            <FaTwitter className="text-2xl" />
          </ExternalLink>
        </div>*/}
        <div>
          Made with{" "}
          <Heart title="Love">
            <Icon as={HeartIcon} />
          </Heart>
          ,{" "}
          <NextjsLink href="https://nextjs.org/" title="Powered by Next.js" aria-label="Next.js" underline={false}>
            <Icon as={NextjsLogo} />
          </NextjsLink>{" "}
          and{" "}
          <NextjsLink href="https://vercel.com/" title="Deployed on Vercel" underline={false}>
            <Icon as={VercelLogo} />
          </NextjsLink>
        </div>
      </Row>
    </Wrapper>
  );
};

export default Footer;
