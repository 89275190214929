import { HomeIcon, NotesIcon, ProjectsIcon, ContactIcon } from "../../components/Icons";
import type { MenuItemProps } from "../../components/MenuItem";

export const menuItems: MenuItemProps[] = [
  {
    icon: HomeIcon,
    text: "Home",
    href: "/"
  },
  {
    icon: NotesIcon,
    text: "Blog",
    href: "/blog"
  },
  {
    icon: ProjectsIcon,
    text: "Projects",
    href: "/projects"
  }
];
